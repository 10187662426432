import request from '../../util/request'
//添加分子条目参考文献
function add(data) {
    return request({ 
        url: '/references/add' ,
        method:'post',
        data
    })
}
//查询分子条目参考文献列表
function select(data) {
    return request({ 
        url: '/references/select' ,
        method:'post',
        data
    })
}
//删除分子条目参考文献
function del(data) {
    return request({ 
        url: '/references/delete' ,
        method:'post',
        data
    })
}
export{
    add,
    select,
    del
}