import { select,add,edit,delFile,selectFile,downloadFile } from '../../api/moleculeInfo'
import store from '../../store'
const actions = {
    //分页查询分子条目信息列表
    select({ commit },data){
        return new Promise((resolve, reject) => {
            select(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //添加分子条目信息
    add({ commit },data){
        return new Promise((resolve, reject) => {
            add(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //编辑分子条目信息
    edit({ commit },data){
        return new Promise((resolve, reject) => {
            edit(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //删除分子条目相关文件
    delFile({ commit },data){
        return new Promise((resolve, reject) => {
            delFile(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //查看分子条目相关文件
    selectFile({ commit },data){
        return new Promise((resolve, reject) => {
            selectFile(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //下载分子条目相关文件
    downloadFile({ commit },data){
        return new Promise((resolve, reject) => {
            downloadFile(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    actions
}