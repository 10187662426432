import request from '../../util/request'
//分页查询分子条目信息列表
function select(data) {
    return request({ 
        url: 'mymoleculeinfo/select/'+data.pagesize+'/'+data.pagenum ,
        method:'get'
    })
}
export{
    select
}