import { select,selectLatest,selectAboutUs } from '../../api/article'
const actions = {
    select({ commit },data){
        return new Promise((resolve, reject) => {
            select(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    selectLatest({ commit },data){
        return new Promise((resolve, reject) => {
            selectLatest(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    selectAboutUs({ commit }){
        return new Promise((resolve, reject) => {
            selectAboutUs().then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    }
}
export default {
    namespaced: true,
    actions
}