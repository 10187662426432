import request from '../../util/request'
function select(data) {
    var url = ""
    if (data.keyWord === undefined || data.keyWord === null || data.keyWord === "") {
        url = '/mynetworkmodel/select/'+data.pagesize+'/'+data.pagenum
    }else{
        url = '/mynetworkmodel/select/'+data.pagesize+'/'+data.pagenum + '?keyword=' + data.keyWord 
    }
    return request({ 
        
        url: url ,
        method:'get'
    })
}
function add(data) {
    return request({ 
        url: '/networkmodel/add' ,
        method:'post',
        data
    })
}
function edit(data) {
    return request({ 
        url: '/networkmodel/edit' ,
        method:'post',
        data
    })
}
function del(data) {
    return request({ 
        url: '/networkmodel/delete' ,
        method:'post',
        data
    })
}
export{
    select,
    add,
    edit,
    del
}