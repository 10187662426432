import request from '../../util/request'
function select(data) {
    var url = ""
    if (data.keyWord === undefined || data.keyWord === null || data.keyWord === "") {
        url = '/networkmodel/select/passed/'+data.pagesize+'/'+data.pagenum
    }else{
        url = '/networkmodel/select/passed/'+data.pagesize+'/'+data.pagenum + '?keyword=' + data.keyWord 
    }
    return request({ 
        
        url: url ,
        method:'get'
    })
}
export{
    select
}