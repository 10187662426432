import request from '../../util/request'
function select(data) {
    var url = ""
    if (data.keyWord === undefined || data.keyWord === null || data.keyWord === "") {
        url = '/article/select/published/' + data.articleTypeUnCode + '/' +data.pagesize + '/' + data.pagenum
    } else {
        url = '/article/select/published/' + data.articleTypeUnCode + '/' +data.pagesize + '/' + data.pagenum + '?keyword=' + data.keyWord
    }
    return request({

        url: url,
        method: 'get'
    })
}
function selectLatest(){
    return request({
        url: '/article/select/latest',
        method: 'get'
    })
}
function selectAboutUs(){
    return request({
        url: '/article/select/about/us',
        method: 'get'
    })
}
export{
    select,
    selectLatest,
    selectAboutUs
}