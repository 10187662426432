import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { Message } from 'element-ui'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login','/home','/','/articleSelect','/getAnAccount'] // 不进行重定向的白名单
/**前置路由守卫 */
router.beforeEach((to, from, next) => {
    //进度条开启
    NProgress.start()
    if (store.state.tokenObj) {//如果存在token
        if (to.path === '/login') { // 在已经登录的情况下如果是要去往登录页面则重定向至首页
            next({ path: '/home' })
            NProgress.done()
        } else{
            next()
            NProgress.done()
        }
    }else { // 没有token的情况
        if (whiteList.indexOf(to.path) !== -1) { // 是否在白名单中
            // in the free login whitelist, go directly
            next()
            NProgress.done()
        } else { // 不在白名单中则重定向到登录页进行登录
            next(`/login?redirect=${to.path}`)
            Message.error("The verification information has expired, please log in again！");
            NProgress.done()
        }
    }
    
})
/**路由后置守卫 */
router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})