import {select,userSelect,add} from "../../api/propertieRecord"
const actions = {
    //查询分子性质记录
    select({ commit },data){
        return new Promise((resolve, reject) => {
            select(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //用户分页查询分子性质编辑记录
    userSelect({ commit },data){
        return new Promise((resolve, reject) => {
            userSelect(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //添加分子性质记录
    add({ commit },data){
        return new Promise((resolve, reject) => {
            add(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    actions
}