import { select } from "../../api/myMoleculeInfo"
const actions = {
    //分页查询我的分子条目信息列表
    select({ commit }, data) {
        return new Promise((resolve, reject) => {
            select(data).then(res => {
                const { data } = res
                if (!data.success) {
                    reject(data)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    }
}
export default {
    namespaced: true,
    actions
}