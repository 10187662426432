import request from '../../util/request'
//获取分子性质编辑审核记录详情
function selectDetail(data) {
    return request({ 
        url: '/propertierecordauditrecord/selectdetail' ,
        method:'post',
        data
    })
}

export{
    selectDetail
}