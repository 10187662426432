import { login,me,getVolidCode } from '../../api/user'
import store from '../../store'
const actions = {
    //用户登录
    login({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                const { data } = response
                if (!data.success) {
                    store.commit('removeTokenInfo')
                    reject(data)

                }
                store.commit('setTokenInfo', { token: data.data.token })
                store.commit('setUserInfo', data.data.user )
                resolve(data) // 执行成功执行的函数，有了它调用该函数的方法才能使用then
            }).catch(error => {
                store.commit('removeTokenInfo')
                //清除用户信息
                store.commit('removeUserInfo')
                reject(error) // 执行失败执行的函数，有了它调用该函数的方法才能使用catch
            })
        })
    },
    //获取用户个人信息
    me({ commit }) {
        return new Promise((resolve, reject) => {
            me().then(response => {
                const { data } = response
                if (!data.success) {
                    store.commit('removeTokenInfo')
                    reject(data)

                }
                resolve(data) // 执行成功执行的函数，有了它调用该函数的方法才能使用then
            }).catch(error => {
                store.commit('removeTokenInfo')
                //清除用户信息
                store.commit('removeUserInfo')
                reject(error) // 执行失败执行的函数，有了它调用该函数的方法才能使用catch
            })
        })
    },
    //获取验证码
    getVolidCode({ commit }){
    	return new Promise((resolve, reject)=>{
    		getVolidCode().then(response=>{
    			const { data } = response
                if (!data.success) {
                    reject(data)
                }
                resolve(data) // 执行成功执行的函数，有了它调用该函数的方法才能使用then
          }).catch(error => {
                //store.commit('captcha')
                reject(error) // 执行失败执行的函数，有了它调用该函数的方法才能使用catch
    		})
    	})
    },
    //用户退出登录
    loginOut({ commit }) {
        const data = {
            success: true
        }
        return new Promise((resolve, reject) => {
            store.commit('removeTokenInfo')
            //清除用户信息
            store.commit('removeUserInfo')
            resolve(data)

        })

    },
}
export default {
    namespaced: true,
    actions
}