import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    hidden: true,//不在导航列表中显示
    redirect: '/home',  //当路由未匹配时重定向，可作初始划显示页面设置
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/index')
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/Main/index'),
    redirect: '/home',
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import('../views/Home/index')
      },
      {
        path: "/database",
        name: "DataBase",
        component: () => import('../views/DataBase/index'),
        redirect: '/moleculeInfoSelect',
        children: [{
          path: "/moleculeInfoSelect",
          name: "MoleculeInfoSelect",
          component: () => import('../views/MoleculeInfoManage/MoleculeInfoSelect/index')
        }, {
          path: "/myMoleculeInfoSelect",
          name: "MyMoleculeInfoSelect",
          component: () => import('../views/MyMoleculeInfoManage/MyMoleculeInfoSelect')
        }, {
          path: "/myMoleculeInfoAdd",
          name: "MyMoleculeInfoAdd",
          component: () => import('../views/MyMoleculeInfoManage/MyMoleculeInfoAdd')
        }, {
          path: "/moleculeInfoEdit",
          name: "MoleculeInfoEdit",
          component: () => import('../views/MoleculeInfoManage/MoleculeInfoEdit/index')
        }, {
          path: "/propertieRecordEditRecordSelect",
          name: "PropertieRecordEditRecordSelect",
          component: () => import('../views/PropertieRecordManage/PropertieRecordEditRecordSelect/index')
        }]
      }, {
        path: "/networkModel",
        name: "NetworkModel",
        component: () => import('../views/NetworkModelManage/index'),
        redirect: '/networkModelSelect',
        children: [{
          path: "/networkModelSelect",
          name: "NetworkModelSelect",
          component: () => import('../views/NetworkModelManage/NetworkModelSelect')
        }, {
          path: "/myNetworkModelSelect",
          name: "MyNetworkModelSelect",
          component: () => import('../views/MyNetworkModelManage/MyNetworkModelSelect')
        }]
      },
      {
        path: "/article",
        name: "Article",
        component: () => import('../views/ArticleManage/index'),
        redirect: '/articleSelect',
        children: [{
          path: "/articleSelect",
          name: "ArticleSelect",
          component: () => import('../views/ArticleManage/ArticleSelect')
        }]
      },
      {
        path: "/getAnAccount",
        name: "AboutUs",
        component: () => import('../views/AboutUs/index')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash', // 去掉#，需要路由模式改为history
  routes
})

export default router
