import request from '../../util/request'
function login(data) {
    return request({ 
        url: '/auth/login' ,
        method:'post',
        data:{
            account:data.account,
            password:data.password,
            captcha:data.validcode,
            verify_value:data.verify_value
        }
    })
}
//获取用户个人信息
function me(){
    return request({ 
        url: '/user/me' ,
        method:'get'
    })
}
//获取验证码
function getVolidCode(){
	return request({
		url: '/auth/get/captcha' ,
		method: 'get'
	})
}
export{
    login,
    me,
    getVolidCode
}