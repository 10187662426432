import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import article from './modules/article'
import articleType from './modules/articleType'
import moleculeInfo from './modules/moleculeInfo'
import myMoleculeInfo from './modules/myMoleculeInfo'
import propertieRecord from './modules/propertieRecord'
import propertieRecordAuditRecord from './modules/propertieRecordAuditRecord'
import references from './modules/references'
import networkModel from './modules/networkModel'
import myNetworkModel from './modules/myNetworkModel'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    //用户信息对象
    userObj:JSON.parse(localStorage.getItem('userObj')) || null,
    //保存token
    //我们需要让该对象要么等于从本地存储获取到的token对象，或者空对象
    //需要将Token对象字符串装换为token对象
    tokenObj: JSON.parse(localStorage.getItem('token')) || null,
    //刷新Token,如果需要的话可以使用，如果不需要的话可以忽略
    refreshToken: JSON.parse(localStorage.getItem('refreshToken')) || null,
    //最新文章unCode
    latestArticleUncode : JSON.parse(localStorage.getItem('latestArticleUncode')) || null,
  },
  mutations: {
    //设置用户信息对象
    setUserInfo(state, obj) {
      state.userObj = obj
      localStorage.setItem('userObj', JSON.stringify(obj))

    },
    //清除用户信息对象
    removeUserInfo(state) {
      state.userObj = null
      localStorage.removeItem('userObj')

    },
    //设置token
    setTokenInfo(state, obj) {
      state.tokenObj = obj
      localStorage.setItem('token', JSON.stringify(obj))

    },
    //移除token
    removeTokenInfo(state){
      state.tokenObj = null
      localStorage.removeItem('token')
    },
    //设置刷新token
    setRefreshToken(state, obj) {
      state.refreshToken = obj
      localStorage.setItem('refreshToken', JSON.stringify(obj))
    },
    //设置最新文章unCode
    setLatestArticleUncode(state, obj) {
      state.latestArticleUncode = obj
      localStorage.setItem('latestArticleUncode', JSON.stringify(obj))
    },
    //移除最新文章unCode
    removeLatestArticleUncode(state){
      state.latestArticleUncode = null
      localStorage.removeItem('latestArticleUncode')
    },
  },
  actions: {
  },
  modules: {
    user,
    article,
    articleType,
    moleculeInfo,
    myMoleculeInfo,
    propertieRecord,
    propertieRecordAuditRecord,
    references,
    networkModel,
    myNetworkModel
  }
})
export default store
