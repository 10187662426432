import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import '../theme/index.css' //引入自定义element样式
import './assets/index.css'
import './permission' //引入路由守卫
import locale from 'element-ui/lib/locale/lang/en' // 引入英文

Vue.config.productionTip = false
Vue.use(ElementUI, { locale });
AOS.init({
  duration: 1000,   //持续时间
}) //初始化
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
