import request from '../../util/request'
//查询分子性质记录
function select(data) {
    return request({ 
        url: '/propertierecord/select' ,
        method:'post',
        data
    })
}
//用户分页查询分子性质编辑记录
function userSelect(data){
    return request({ 
        url: '/propertierecord/edithistory/select/'+data.pagesize+'/'+data.pagenum ,
        method:'get'
    })
}
//添加分子性质记录
function add(data) {
    return request({ 
        url: '/propertierecord/add' ,
        method:'post',
        data
    })
}
export{
    select,
    userSelect,
    add
}