import { select,add,edit,del } from "../../api/myNetworkModel"
import store from '../../store'
const actions = {
    select({ commit }, data) {
        return new Promise((resolve, reject) => {
            select(data).then(res => {
                const { data } = res
                if (!data.success) {
                    reject(data)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    add({ commit }, data) {
        return new Promise((resolve, reject) => {
            add(data).then(res => {
                const { data } = res
                if (!data.success) {
                    reject(data)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    edit({ commit }, data) {
        return new Promise((resolve, reject) => {
            edit(data).then(res => {
                const { data } = res
                if (!data.success) {
                    reject(data)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    del({ commit }, data) {
        return new Promise((resolve, reject) => {
            del(data).then(res => {
                const { data } = res
                if (!data.success) {
                    reject(data)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    }
}
export default {
    namespaced: true,
    actions
}